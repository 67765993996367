import { useEffect, useState } from 'react';
import axios from 'axios';

const useTopicsPageData = (url, params) => {
  const [petitionsByTopic, setPetitionsByTopic] = useState([]);
  const [dotsLoading, setDotsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(true);

  const fetchTopicsPageData = async () => {
    try {
      const res = await axios.get(url, { params });
      res.data.length === 0 && setButtonLoading(false);
      setPetitionsByTopic(res.data.slice(0, 6));
    } catch (err) {
      console.log(err.message);
    } finally {
      setDotsLoading(false);
    }
  };

  useEffect(() => {
    fetchTopicsPageData();
  }, []);

  return { petitionsByTopic, setPetitionsByTopic, dotsLoading, buttonLoading };
};

export default useTopicsPageData;
