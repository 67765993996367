import axios from 'axios';
//import { HOST, LOCAL_JSON } from "../Variables";

const host = window.location.hostname;
const environment = ['dev', 'prod', 'alpha'].find((env) => host.includes(env));
// export const API = "./staticData/petitions.json";
export const API = `${window.location.origin}/staticData/petitions.json`;
export const DEV_2_API = 'https://api-dev.citizengo.org/api/v1/petition';
// The function returns a Promis, so the data will be available in .then ()
export function getData() {
  return axios.get(checkUrl());
}
export function getPetitionData() {
  return axios.get(checkUrl());
}

export const checkHost = (direction, region, accounts, nid) => {
  if (['dev', 'prod', 'alpha'].some((env) => host.includes(env))) {
    return `${checkUrl()}/admin/accounts/${direction}/?region=${
      region === 'hazteoir' ? 'es-es' : region
    }&${nid ? `pid=${nid}` : ''}`;
  } else if (host.includes('localhost')) {
    return `http://localhost:9988/admin/${accounts}/${direction}?region=${
      region === 'hazteoir' ? 'es-es' : region
    }&${nid ? `pid=${nid}` : ''}`;
  } else {
    return `https://admin.citizengo.org/admin/${accounts}/${direction}?region=${
      region === 'hazteoir' ? 'es-es' : region
    }&${nid ? `pid=${nid}` : ''}`;
  }
};

export const checkUrl = () => {
  if (host.includes('localhost')) {
    return 'http://localhost:9988';
  } else if (environment) {
    return `https://api-${environment}.citizengo.org`;
  } else {
    return 'https://api.citizengo.org';
  }
};

export const addImgSourceParam = () => {
  if (['dev', 'prod', 'alpha'].some((env) => host.includes(env))) {
    return '&img_source=new';
  } else {
    return '';
  }
};

export const checkFrontendURL = () => {
  if (host.includes('localhost')) {
    return 'http://localhost:3000';
  } else if (environment) {
    return `https://app-${environment}.citizengo.org`;
  } else {
    return 'https://citizengo.org';
  }
};

export const LP_PARAMS_ENV = () => {
  if (host.includes('localhost')) {
    return 'localhost';
  } else if (environment) {
    return environment;
  } else {
    return 'prod';
  }
};

export const myOptInValues = (segment) => {
  const segmentMap = {
    de: 'de',
    es: 'es',
    en: 'en',
    hazteoir: 'ho',
    pt: 'pt',
  };
  for (let key in segmentMap) {
    if (segment.includes(key)) {
      return segmentMap[key];
    }
  }
  return segment;
};

export const gdprCountries = ['AT','BE','BG','BR','CY','CZ','DE','DK','EE','ES','FI','FR','GB','GR','HR','HU','IE','IT','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK','NO','LI','IS','AX','AD','BL','GF','PF','MF','MQ','NC','PM','RE','UK','WF','YT'];
