import { useState, useEffect } from 'react';
import axios from 'axios';
import { checkUrl } from '../components/Data';
import { useTranslation } from 'react-i18next';

const useCountries = () => {
    const [countries, setCountries] = useState([]);

    const { t } = useTranslation();
    
    useEffect(() => {
        const fetchCountries = async () => {
        try {
            const res = await axios.get(`${checkUrl()}/api/get_country_list`);
            setCountries(res.data.map((country) => {
                return {
                    code: country?.code,
                    name: t("country_list." + country?.name),
                }
            }));
        } catch (error) {
            console.error(error);
        }
        };
    
        fetchCountries();
    }, []);
    
    return { countries };
    };

export default useCountries;