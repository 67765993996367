import React, { createContext } from 'react';
import '../i18next';
import { useTranslation } from 'react-i18next';

export const GlobalCtx = createContext();

const GlobalContext = ({ children }) => {
  const { i18n: { language } } = useTranslation();

  return (
    <GlobalCtx.Provider value={{
      lang: language
    }} >
      {children}
    </GlobalCtx.Provider>
  );
};

export default GlobalContext;
