import { useState, useEffect } from 'react';
import axios from 'axios';
import he from 'he';
import { topicsShortcutFunc, URL_EXCEPTION_REGEX } from '../Variables';
import { checkUrl } from '../components/Data';

const useShareData = (url, lang, origin, utmCampaign, refParam, flowType) => {
  const [petitionData, setPetitionData] = useState([]);
  const [australianFlow, setAustralianFlow] = useState(false);
  const [plainWhatsappMessage, setPlainWhatsappMessage] = useState('');
  const [petitionURL, setPetitionURL] = useState('');
  const [shortParam, setShortParam] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [flowTypeParam, setFlowTypeParam] = useState(false);
  
  const createShortParam = async (petition_full_URL) => {
    const formData = new URLSearchParams({
        full_url: `${petition_full_URL}?utm_medium=shared&utm_campaign=${
            utmCampaign ? utmCampaign : 'typage'
          }&_ref=${refParam ? refParam : ''}`
    });

    const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      
    try {
        const res = await axios.post(`${checkUrl()}/api/v1/store-shortcut`, formData, config);
        setShortParam(res.data.shortcut);
        setShortUrl(`https://cgo.ac/${res.data.shortcut}`);
    } catch(err) {
        console.error(err);
    }
  }

  const fetchData = async () => {
    if (flowType === 'don') setFlowTypeParam(true);
    try {
      const res = await axios.get(url);
      setPetitionData(res.data[0]);
      if (res?.data[0]?.topic?.code === 'ele' && lang === 'en-au')
        setAustralianFlow(true);
    const { whatsapp_custom_msg } = res?.data[0];
    setPlainWhatsappMessage(whatsapp_custom_msg ? he.decode(whatsapp_custom_msg.replace(/<[^>]*>/g, '')) : '');
    setPetitionURL(`${origin}/${lang}/${topicsShortcutFunc(res?.data[0]?.topic?.code)}/${res?.data[0]?.id}-${res?.data[0]?.title.replace(URL_EXCEPTION_REGEX, '-')}`);
    createShortParam(`${origin}/${lang}/${topicsShortcutFunc(res?.data[0]?.topic?.code)}/${res?.data[0]?.id}-${res?.data[0]?.title.replace(URL_EXCEPTION_REGEX, '-')}`)
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return { petitionData, australianFlow, plainWhatsappMessage, petitionURL, shortParam, shortUrl, flowTypeParam };
};

export default useShareData;
