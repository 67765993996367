import { useState, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalCtx } from './GlobalContext';
import {
  checkFrontendURL,
  checkUrl,
  addImgSourceParam,
} from '../components/Data';
import { OTD_BASE_URL, LANGUAGES } from '../Variables';
import useShareData from '../hooks/useShareData';
import axios from 'axios';

export const SharePageCtx = createContext();

const ShareCtx = ({ children }) => {
  const { lang } = useContext(GlobalCtx);
  const SHARE_PAGE_PARAMS = new URLSearchParams(window.location.search);
  const id = SHARE_PAGE_PARAMS.get('nid');
  const utm_campaign = SHARE_PAGE_PARAMS.get('utm_campaign');
  const ref = SHARE_PAGE_PARAMS.get('sid');
  const TITLE = SHARE_PAGE_PARAMS.get('title');
  const mPARAM = SHARE_PAGE_PARAMS.get('m');
  const flowType = SHARE_PAGE_PARAMS.get('flowType');

  const {
    petitionData,
    australianFlow,
    plainWhatsappMessage,
    petitionURL,
    shortParam,
    shortUrl,
    flowTypeParam,
  } = useShareData(
    `${checkUrl()}/api/v1/single_petition/?id=${id}`,
    lang,
    window.location.origin,
    utm_campaign,
    ref,
    flowType
  );

  const [nextPage, setNextPage] = useState({
    HomePage: false,
    ThankyouPage: false,
    Donate: false,
    LP3Donate: false,
  });
  const [donationURL, setDonationURL] = useState('');

  const setNextPageTo = (page) => {
    const newPageState = {
      HomePage: false,
      ThankyouPage: false,
      Donate: false,
      LP3Donate: false,
    };
    newPageState[page] = true;
    setNextPage(newPageState);
  };

  const defineDonationURL = () => {
    const OTD_URL = LANGUAGES.find((item) => item.code === lang?.toLowerCase());
    setDonationURL(`${OTD_BASE_URL}/${OTD_URL?.shareDonation}.html`);
  }

  const nextPageHandler = () => {
    if (
      flowTypeParam ||
      australianFlow ||
      ((petitionData.after_sign === 'sho') && !petitionData.thankyou_page)
    ) {
      setNextPageTo('HomePage');
    } else if (
      !petitionData.thankyou_page &&
      (petitionData.after_sign === 'shr' || petitionData.after_sign === 'sdt')
    ) {
      setNextPageTo('Donate');
    } else if (
      petitionData.after_sign === 'cus' &&
      petitionData.thankyou_page
    ) {
      setNextPageTo('ThankyouPage');
    }
  };

  const upadteshortParamHandler = async (source) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const fullUrl = `${petitionURL}?utm_medium=shared&utm_campaign=${
      utm_campaign ? utm_campaign : 'typage'
    }&utm_source=${source}&_ref=${ref ? ref : ''}`;

    const formData = new URLSearchParams({
      full_url: fullUrl.toString(),
      short_url: shortParam.slice(2),
    }).toString();

    try {
      axios.put(`${checkUrl()}/api/v1/store-shortcut`, formData, config);
    } catch (err) {
      console.error(err);
    }
  };

  const redirectionHandler = () => {
    const PARAMS = `?m=${mPARAM ? mPARAM : ''}&nid=${id}${
      utm_campaign ? `&utm_campaign=${utm_campaign}` : ''
    }${ref ? `&_ref=${ref}` : ''}&title=${TITLE}&flowType=${
      petitionData?.after_sign
    }${addImgSourceParam()}`;

    let url;
    if (nextPage.HomePage) {
      url = checkFrontendURL();
    } else if (nextPage.ThankyouPage) {
      url = `${petitionData.thankyou_page}${utm_campaign ? `?utm_campaign=${utm_campaign}` : ''}`;
    } else if (nextPage.Donate) {
      url = `${donationURL}${PARAMS}`;
    } else if (nextPage.LP3Donate) {
      url = `/LP-3-donate${PARAMS}`;
    }
  
    if (url) {
      window.location.href = url;
    }
  };

  const createViberMessage = (message) => {
    if (message.length > 175) {
      return `${message.slice(0, 170)}...`;
    }
  };

  const LP2_content_handler = (
    c_content, // custom content from petition panel
    d_donation_content, // default donation text from i18next
    donation_type, // if prev step was donation
    d_share_content // default share text from i18next
  ) => {
    if (c_content) {
      return c_content;
    } else if (donation_type && !c_content) {
      return d_donation_content;
    } else return d_share_content;
  };

  return (
    <SharePageCtx.Provider
      value={{
        petitionData,
        australianFlow,
        plainWhatsappMessage,
        petitionURL,
        shortParam,
        shortUrl,
        flowTypeParam,
        mPARAM,
        TITLE,
        utm_campaign,
        ref,
        nextPage,
        donationURL,
        upadteshortParamHandler,
        nextPageHandler,
        redirectionHandler,
        LP2_content_handler,
        createViberMessage,
        defineDonationURL
      }}
    >
      {children}
    </SharePageCtx.Provider>
  );
};

export default ShareCtx;
