import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

const useVictoriesPageData = (url, params) => {
  const [victoriesData, setVictoriesData] = useState([]);
  const [dotsLoading, setDotsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(true);
  const fetchVictoriesData = async () => {
    try {
      setDotsLoading(true)
      const res = await axios.get(
        `${url}?${qs.stringify(params, { arrayFormat: 'repeat' })}`
      );
      res.data.length === 0 && setButtonLoading(false);
      setVictoriesData(res.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setDotsLoading(false)
    }
  };

  useEffect(() => {
    fetchVictoriesData();
  }, []);
  return { victoriesData, setVictoriesData, setButtonLoading, dotsLoading, buttonLoading };
};

export default useVictoriesPageData;
