import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

const useVictoriesSlider = (url, params) => {
  const [victoriesSlider, setVictoriesSlider] = useState([]);
  const fetchVictoriesPetitions = async () => {
    try {
      const res = await axios.get(
        `${url}?${qs.stringify(params, { arrayFormat: 'repeat' })}`
      );
      setVictoriesSlider(res.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchVictoriesPetitions();
  }, []);

  return { victoriesSlider };
};

export default useVictoriesSlider;
