import axios from 'axios';

const loadMore = async (url, params, updateStateFn, setOffset, setListEnd) => {

    try {
      const res = await axios.get(url, { params });
      updateStateFn((prevstate) => [...prevstate, ...res.data.slice(0, 5)]);
      setOffset((prevstate) => prevstate + 5);
      res.data.length < 5 && setListEnd(true);
    } catch (err) {
      console.log(err.message);
    }
  };

export default loadMore;
